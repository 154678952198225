const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menu',
      sobreAFeira: 'about-a-feira',
      sicc: 'sicc',
      gramado: 'gramado',
      serraPark: 'serra-park',
      parceiros: 'partners',
      grupoDeImportadores: 'importer-group',
      estacao3: 'station-3',
      conectech: 'conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'hosting',
      traslado: 'transfer',
      credenciamento: 'accreditation',
      agenciaDeTurismo: 'agencia-de-turismo',
      expositores: 'exhibitors',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios:
        'bolsas-acessorios-e-artefatos-de-couro',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'floor plan',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-talks',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'press',
      noticias: 'news',
      galeriaDeImagens: 'image-gallery',
      contato: 'contact',
      programacoes: 'schedules',
      protocolo: 'protocol',
    },
    cabecalho: {
      painelExpositor: 'Exhibitor Panel',
      menu: 'Menu',
      sobreAFeira: 'About the Fair',
      sicc: 'SICC',
      gramado: 'Gramado',
      serraPark: 'Serra Park',
      parceiros: 'Partners',
      grupoDeImportadores: 'Importer Group',
      estacao3: 'Station 3',
      conectech: 'Conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'Accommodation',
      traslado: 'Transfer',
      credenciamento: 'Accreditation',
      agenciaDeTurismo: 'Tourism Agency',
      expositores: 'Exhibitors',
      plantaBaixa: 'Floor Plan',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'eMerkator Talks',
      eMerkatorBlog: 'eMerkator Blog',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Press',
      noticias: 'News',
      galeriaDeImagens: 'Image Gallery',
      contato: 'Contact',
    },
    paginas: {
      inicial: {
        secaoSicc: {
          titulo: 'SICC - International Leather and Footwear Exhibition',
          subTitulo: 'The new face of fashion',
          botaoQueroVisitar: 'I want to visit',
          botaoProtocolo: 'Protocolo de medidas de prevenção ao Covid-19',
          botaoGaleria: 'Gallery',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Gramado',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Serra Park',
          subTitulo: 'Viação Férrea, nº 100, Gramado/RS',
          conteudo: '',
          tituloCaracteristicas: 'Features',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Partners',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Importers Group',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Station 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'I want to visit',
          hospedagem: {
            titulo: 'ACCOMMODATION',
            conteudo:
              '<p>Merkator Feiras e Eventos, promoter of Zero Grau, will provide courtesy accommodation in hotels in Serra Gaúcha, during the period of the fair. Courtesies are exclusive to retailers in the footwear sector.</p>',
            textoBotao: 'Request here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFER',
            conteudo:
              '<p> Transfer from Salgado Filho International Airport, in Porto Alegre - Rio Grande do Sul, accredited hotels will be accredited; carried out through the official transport of the SICC, with the objective of offering greater convenience, comfort and safety to our visitors. </p>',
            textoBotao: 'Times and values here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'ACCREDITATION',
            conteudo:
              '<p> Merkator wants to facilitate your entry to the fair. With this procedure you have a badge ready waiting for you at the window of Early Accreditation and power enter the fair to learn about the main spring-summer launches in the lime and accessories industries in Brazil. </p>',
            textoBotao: 'Register here',
            textoEmBreve: 'Availabe Soon',
            subTextoBotao: 'Accreditation available soon',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Tourism agency',
          conteudo:
            "<p> Sunpower Turismo, & eacute; the fair's official travel agency. </p> <p> It has a team of qualified and experienced professionals, always providing a quality, personalized service, with a focus on customer satisfaction. </p> <p> We offer all travel logistic services with special discounts for the fair's participants / exhibitors. </p> <p> - Accommodation; <br /> - Passage to & eacute; area; <br /> - Car rental. </p> <p> We remind you that reservations requested in advance guarantee the best rates and conditions! </p> <p> More information & ccedil; & otilde; es, follow our contacts: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>",
          subTitulo: 'Official Tourism Agency',
        },
        secaoExpositores: {
          titulo: 'Exhibitors',
          subTitulo: 'Select the desired product:',
          subTituloBreve: 'Availabe Soon',
        },
        secaoPlantaDaFeira: {
          titulo: 'Floor plan',
          description: 'Click here to download',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Connects you to the market',
          textoLinkProgramacao: 'Full schedule',
        },
        secaoImprensa: {
          titulo: 'Press',
          noticias: 'News',
          galeriaDeImagens: 'Image gallery',
          falarComImprensa: 'I want to speak to the press office',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'News',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'News',
          tituloNoticia:
            'Challenging year: promoter transits in virtual and in-person environments to hold events before and during the pandemic',
          legendaImagem:
            'Frederico at a press conference at Zero Grau 2020 - Photo: Dinarci Borges',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galleries',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'Schedules',
          diasSemana: {
            0: 'Sunday',
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Sunday',
          },
          noRegister: 'Coming soon full schedule',
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'Schedule',
        },
      },
      traslado: {
        titulo: 'Transfer',
        description:
          "<p>Transfer from Salgado Filho International Airport, in Porto Alegre - Rio Grande do Sul, to the selected hotels will be performed by Zero Grau official outsourced transportation team as way to provide visitors greater comfort and safety.</p><p class='font-bold-blue'>To use Zero Grau's official transport, it is mandatory to present the fair's credential. The use of the service is exclusive to fair participants.</p><p>The Merkator team, Zero Grau – Footwear and Acessories Fair, will be at the arrival terminal coordinating the above mentioned hotel transfers.</p><p>Customer Service:<br><b>+55 54 99163-1410 (Whatsapp)<br />+55 54 3282-5445 (Phone - General Service)<br /> eventos@brockerturismo.com.br</b></p>",
        textTrasnporteOficial: 'Official Transport',
        textBotaoComprar: 'Buy Tickets',
        textBotaoValores: 'Values',
        textEventoDiaUm: 'Sunday 19.11',
        textEventoDiaDois: 'Monday 20.11',
        textEventoDiaTres: 'Tuesday 21.11',
        textEventoDiaQuatro: 'Wednesday 22.11',
        textEventoDiaCinco: 'Thursday 23.11',
        textTitleDescriptionDiaUm:
          "<p>Departure from Porto Alegre Airport to the Fair's partner hotels</p>",
        textDescriptionDiaUm:
          '<p><b>10am | 12am | 14pm | 16pm | 18pm | 20pm | 22pm *</b></p><p><b>NOTE: If these times do not meet your needs, please contact the Brocker Turismo team directly, our official partner.</b><br />* The times set out above may change depending on need and demand.</p>',
        textTitleDescriptionDiaDois:
          '<p>Departure from Porto Alegre Airport to the fair.</p>',
        textDescriptionDiaDois:
          '<p>8am | 10am | 12pm | 2pm | 4pm | 6pm | 20pm | 10pm</p></p>',
        textTitleDescriptionDiaTres:
          '<p>Departure from Porto Alegre Airport to the fair.</p>',
        textTitleDescriptionDiaTresV:
          '<p>Departure from the fair to Porto Alegre Airport</p>',
        textDescriptionDiaTres: '<p><b>9am</b></p>',
        textDescriptionDiaTresV:
          '<p>2:30pm (Estimated time)</p><p>It is the sole responsibility of the shopkeeper to schedule his return, at the transfer desk at the registration 2 of the fair.</p>',
        textTitleDescriptionDiaQuatro:
          '<p>Only Return from Gramado (from the Fair), the times must be scheduled at the Transfer desk at the fair.</p>',
        textTitleDescriptionDiaQuatroV:
          '<p>Departure from the fair to Porto Alegre Airport</p>',
        textDescriptionDiaQuatro: '<p></p>',
        textDescriptionDiaQuatroV:
          '<p><b>10am | 12am | 14pm | 16pm | 18pm | 20pm | 22pm *</b></p><p><b>NOTE: If these times do not meet your needs, please contact the Brocker Turismo team directly, our official partner.</b><br />* The times set out above may change depending on need and demand.</p>',
        textTitleDescriptionDiaCinco:
          '<p>Departure from Gramado to Porto Alegre Airport</p>',
        textDescriptionDiaCinco:
          '<p><b>4am | 6am | 8am | 10am | 12am | 2pm</b></p>',
        titleTicketUnico: 'SINGLE <br /> TICKET',
        titleDescriptionUnico:
          '<p>Airport (POA) > Gramado/Canela or Gramado/Canela > Airport (POA)</p>',
        precoTicketUnico: 'R$ 100,00',
        comboTicketUnico: 'ROUND TRIP<br />COMBO',
        comboDescriptionUnico:
          '<p>Airport (POA) > Gramado/Canela and Gramado/Canela > Airport (POA)</p>',
        precoComboTicketUnico: 'R$ 230,00',
        observacaoIdaVolta:
          'In case of cancellation of the round trip package, the amount of R$ 100.00 per segment will be deducted.',
        titleTicketAvulso: 'INTERNAL <br /> TICKET',
        descriptionTicketAvulso:
          '<p>Internal Transfer <br />Accredited hotel / Serra Park – Serra Park / Accredited Hotel (Three days)</p>',
        precoTicketAvulso: 'R$ 90,00',
        observacaoTicketAvulso:
          'Purchasing, the round trip transfer from the Airport to Gramado/Canela, the internal transfer is free.',
        titleComboFeira: 'FAIR <br /> COMBO',
        descriptionComboFeira:
          '<p>Hotel for fair and<br />Fair for hotel </br> (every day of the event)</p>',
        precoComboFeira: '<p>R$ 20,00</p>',
        porPessoa: '',
        textCompreAqui: 'BUY TICKETS',
        descriptionValor: '<p></p>',
        observationBoxValue: '',
      },
      expositores: {
        titulo: 'Exhibitors',
        botaoCategoria: {
          listaCompleta: 'Full list',
          feminino: 'Footwear for Women',
          masculino: 'Footwear for Men',
          infantil: 'Footwear for Kids',
          acessorios: 'Bag And Accessories',
          esportivos: 'Sports Shoes',
        },
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Page not found.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Email',
        senha: 'Password',
        entrar: 'Enter',
        cancelar: 'Cancel',
        esqueciMinhaSenha: 'I forgot my password',
      },
      secao: {
        botaoVoltar: 'Back',
      },
      cabecalho: {
        linguagem: 'Language',
        portugues: 'Portuguese',
        ingles: 'English',
        espanhol: 'Spanish',
      },
      rodape: {
        baixeNossoApp: 'Download our app',
        disponivelNa: 'Available in',
      },
      secaoEMerkator: {
        programacaoCompleta: 'Full schedule',
        // programacaoCompleta: 'Coming soon',
      },
    },
  },
};

export default traducoes;
