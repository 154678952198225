import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Caminho539 from '../../../assets/caminho-539.png';
import Grupo205 from '../../../assets/grupo-205.png';
import {
  Botao,
  BoxImagem,
  Caixa,
  Col,
  Conteudo,
  Imagem,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  SubTextoBotao,
  BotaoLinkExterno,
  TextoBotao,
  Titulo,
  Tradutor,
} from './styles';

const SecaoQueroVisitar = ({ iconeCredenciamento, showTitle }) => {
  const { t } = useTranslation();
  const secaoId = t('slugs.queroVisitar');
  const { lang } = useParams();

  const setLanguageAccreditation = (lang) => {
    var defaultUrl =
      'https://sigevent.pro/merkator/visitantes/formularios.php?id_edicao=271';
    switch (lang) {
      case 'es':
        return `${defaultUrl}&lingua=espanhol&id_visitante=null`;
      // return `${defaultUrl}`;
      case 'en':
        return `${defaultUrl}&lingua=ingles&id_visitante=null`;
      // return `${defaultUrl}`;
      default:
        return `${defaultUrl}`;
    }
  };

  return (
    <Secao id={secaoId} className="mb-4">
      {showTitle && (
        <Fragment>
          <SecaoCabecalho className="mb-4 mb-30">
            <SecaoTitulo>
              <Tradutor path="paginas.inicial.secaoQueroVisitar.titulo" />
            </SecaoTitulo>
            <SecaoBotaoVoltar />
          </SecaoCabecalho>
        </Fragment>
      )}
      <Row>
        <Col md="4">
          <Caixa>
            <Titulo>
              <Tradutor path="paginas.inicial.secaoQueroVisitar.hospedagem.titulo" />
            </Titulo>
            <BoxImagem>
              <Imagem src={Grupo205} />
            </BoxImagem>
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: t(
                  'paginas.inicial.secaoQueroVisitar.hospedagem.conteudo'
                ),
              }}
            ></Conteudo>
            <BotaoLinkExterno
              href="https://sigevent.pro/merkator/hospedagem/solicitacaowebempresa/index.php?id_edicao=271&linguagem=portugues"
              // href="#quero-visitar"
              title="Solicite a sua hospedagem"
              target="_blank"
            >
              <TextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.hospedagem.textoBotao" />
              </TextoBotao>
              <SubTextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.hospedagem.subTextoBotao" />
              </SubTextoBotao>
            </BotaoLinkExterno>
          </Caixa>
        </Col>
        <Col md="4">
          <Caixa>
            <Titulo>
              <Tradutor path="paginas.inicial.secaoQueroVisitar.translado.titulo" />
            </Titulo>
            <BoxImagem>
              <Imagem src={Caminho539} />
            </BoxImagem>
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: t(
                  'paginas.inicial.secaoQueroVisitar.translado.conteudo'
                ),
              }}
            ></Conteudo>
            <Botao to={`/${lang}/${t('slugs.traslado')}`}>
              {/* <BotaoLinkExterno href="#quero-visitar"> */}
              <TextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.translado.textoBotao" />
              </TextoBotao>
              {/* <SubTextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.translado.textoBotao" />
              </SubTextoBotao> */}
              {/* </BotaoLinkExterno> */}
            </Botao>
          </Caixa>
        </Col>

        <Col md="4">
          <Caixa>
            <Titulo>
              <Tradutor path="paginas.inicial.secaoQueroVisitar.credenciamento.titulo" />
            </Titulo>
            <BoxImagem>
              <Imagem src={iconeCredenciamento} />
            </BoxImagem>
            <Conteudo
              dangerouslySetInnerHTML={{
                __html: t(
                  'paginas.inicial.secaoQueroVisitar.credenciamento.conteudo'
                ),
              }}
            ></Conteudo>
            <BotaoLinkExterno
              // href="#quero-visitar"
              href={setLanguageAccreditation(lang)}
              target="_blank"
            >
              <TextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.credenciamento.textoBotao" />
              </TextoBotao>
              {/* <SubTextoBotao>
                <Tradutor path="paginas.inicial.secaoQueroVisitar.credenciamento.subTextoBotao" />
              </SubTextoBotao> */}
            </BotaoLinkExterno>
          </Caixa>
        </Col>
      </Row>
    </Secao>
  );
};

SecaoQueroVisitar.propTypes = {
  iconeCredenciamento: PropTypes.any,
  showTitle: PropTypes.any,
};

export default SecaoQueroVisitar;
