import React from 'react';

import SecaoImprensa from '@alweb-merkator/shared/pages/PaginaInicial/SecaoImprensa';
import SecaoEMerkator from '@alweb-merkator/shared/pages/PaginaInicial/SecaoEMerkator';
import SecaoPlantaDaFeira from '@alweb-merkator/shared/pages/PaginaInicial/SecaoPlantaDaFeira';
import SecaoExpositores from '@alweb-merkator/shared/pages/PaginaInicial/SecaoExpositoresAtiva';
import SecaoAgenciaDeTurismo from '@alweb-merkator/shared/pages/PaginaInicial/SecaoAgenciaDeTurismo';
import SecaoGrupoDeImportadores from '@alweb-merkator/shared/pages/PaginaInicial/SecaoGrupoDeImportadores';
import SecaoParceiros from '@alweb-merkator/shared/pages/PaginaInicial/SecaoParceiros';
import AlertModal from '@alweb-merkator/shared/components/AlertModal';

import SecaoBanner from './SecaoBanner';
import SecaoFeira from './SecaoFeira';
import SecaoGramado from './SecaoGramado';
import SecaoSerraPark from './SecaoSerraPark';
import SecaoQueroVisitar from './SecaoQueroVisitar';

const PaginaInicial = () => (
  <>
    <SecaoBanner />
    <SecaoFeira idPage={20} />
    <SecaoGramado idPage={22} />
    <SecaoSerraPark idPage={12} />
    <SecaoParceiros idPage={27} />
    <SecaoGrupoDeImportadores idPage={31} />
    <SecaoQueroVisitar showTitle={true} />
    <SecaoAgenciaDeTurismo idPage={15} />
    <SecaoExpositores showCategory={true} />
    <SecaoPlantaDaFeira idPage={23} />
    <SecaoEMerkator contemProgramacao={false} />
    <SecaoImprensa />
    <AlertModal
      imageDescription="Faça seu credenciamento para Zero Grau 2023"
      urlImage="hhttps://merkatorhospedagem.sigevent.com/hospedagem/solicitacaoweb/login.php?id_edicao=31"
      urlTitle="Faça seu credenciamento para Zero Grau 2023"
      imageModal="http://paineldoexpositor.com.br/uploads/media/lg/media_633c34265eeeb7_96189287.png"
      target="_blank"
      showModal={false}
    />
  </>
);

export default PaginaInicial;
